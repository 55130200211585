/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApplicationDTO } from "@ensembleui/react-framework";

import BuildYAML from "./build.yaml?raw";
import AppInfoCard from "./widgets/AppInfoCard.yaml?raw";

import ModulesWidget from "./widgets/ModulesWidget.yaml?raw";

import SupportLinkText from "./widgets/SupportLinkText.yaml?raw";

import UploadFileButton from "./widgets/UploadFileButton.yaml?raw";

// Modules
import ModuleCard from "./widgets/modules/ModuleCard.yaml?raw";
import GoogleMapsModule from "./widgets/modules/GoogleMaps.yaml?raw";
import GoogleLoginAttributes from "./widgets/modules/GoogleLoginAttributes.yaml?raw";
import NotificationsAttributes from "./widgets/modules/NotificationsAttributes.yaml?raw";
import BranchIOModule from "./widgets/modules/BranchIOModule.yaml?raw";

// Android
import AndroidAppInfo from "./widgets/android/AndroidAppInfo.yaml?raw";
import GooglePlayDeployment from "./widgets/android/GooglePlayDeployment.yaml?raw";
import AssetsCard from "./widgets/assets/AssetsCard.yaml?raw";
import SplashCard from "./widgets/assets/SplashCard.yaml?raw";
import AppLauncherIcon from "./widgets/assets/AppLauncherIcon.yaml?raw";
import Device from "./widgets/assets/Device.yaml?raw";
import SplashScreen from "./widgets/assets/SplashScreen.yaml?raw";
import SplashBackgroundImage from "./widgets/assets/SplashBackgroundImage.yaml?raw";
import SplashIcon from "./widgets/assets/SplashIcon.yaml?raw";
export * from "./widgets/assets/ColorPicker";

// IOS
import IOSAppInfo from "./widgets/ios/IOSAppInfo.yaml?raw";
import PermissionDescriptions from "./widgets/ios/PermissionDescriptions.yaml?raw";
import AppStoreDeployment from "./widgets/ios/AppStoreDeployment.yaml?raw";

// Web
import WebAppInfo from "./widgets/web/WebAppInfo.yaml?raw";

// History
import HistoryLogs from "./widgets/history/HistoryLogs.yaml?raw";
import HistoryDetails from "./widgets/history/HistoryDetails.yaml?raw";

// Build
import BuildModal from "./modals/BuildModal.yaml?raw";
import BuildActionRow from "./widgets/buildWidgets/BuildActionRow.yaml?raw";
import BuildStatusCard from "./widgets/buildWidgets/BuildStatusCard.yaml?raw";
import BuildInProgress from "./widgets/buildWidgets/BuildInProgress.yaml?raw";
import BuildMessage from "./widgets/buildWidgets/BuildMessage.yaml?raw";

// @ts-expect-error
import validationScrips from "./scripts/validation.js?raw";
// @ts-expect-error
import commonScript from "./scripts/common.js?raw";
// @ts-expect-error
import parametersScript from "./scripts/parameters.js?raw";
// @ts-expect-error
import constantsScript from "./scripts/constants.js?raw";

export const BuildApp: ApplicationDTO = {
  id: "build",
  name: "Build",
  scripts: [
    {
      id: "validation_scripts",
      name: "Validation",
      content: String(validationScrips),
    },
    {
      id: "common_scripts",
      name: "Common",
      content: String(commonScript),
    },
    {
      id: "parameters_scripts",
      name: "Parameters",
      content: String(parametersScript),
    },
    {
      id: "constants_scripts",
      name: "Constants",
      content: String(constantsScript),
    },
  ],
  widgets: [
    {
      id: "app_info_card",
      name: "AppInfoCard",
      content: AppInfoCard,
    },
    {
      id: "modules_card",
      name: "ModulesWidget",
      content: ModulesWidget,
    },
    {
      id: "module_card_widget",
      name: "ModuleCard",
      content: ModuleCard,
    },
    {
      id: "google_maps_module",
      name: "GoogleMapsModule",
      content: GoogleMapsModule,
    },
    {
      id: "android_app_info",
      name: "AndroidAppInfo",
      content: AndroidAppInfo,
    },
    {
      id: "google_play_deployment",
      name: "GooglePlayDeployment",
      content: GooglePlayDeployment,
    },
    {
      id: "assets_card",
      name: "AssetsCard",
      content: AssetsCard,
    },
    {
      id: "splash_card",
      name: "SplashCard",
      content: SplashCard,
    },
    {
      id: "assets_widget",
      name: "AppLauncherIcon",
      content: AppLauncherIcon,
    },
    {
      id: "device",
      name: "Device",
      content: Device,
    },
    {
      id: "splash_screen",
      name: "SplashScreen",
      content: SplashScreen,
    },
    {
      id: "splash_background_image",
      name: "SplashBackgroundImage",
      content: SplashBackgroundImage,
    },
    {
      id: "splash_icon",
      name: "SplashIcon",
      content: SplashIcon,
    },
    {
      id: "history_logs",
      name: "HistoryLogs",
      content: HistoryLogs,
    },
    {
      id: "history_details",
      name: "HistoryDetails",
      content: HistoryDetails,
    },
    {
      id: "google_login_attributes",
      name: "GoogleLoginAttributes",
      content: GoogleLoginAttributes,
    },
    {
      id: "notifications_attributes",
      name: "NotificationsAttributes",
      content: NotificationsAttributes,
    },
    {
      id: "branch_io_module",
      name: "BranchIOModule",
      content: BranchIOModule,
    },
    {
      id: "ios_app_info",
      name: "IOSAppInfo",
      content: IOSAppInfo,
    },
    {
      id: "permission_descriptions",
      name: "PermissionDescriptions",
      content: PermissionDescriptions,
    },
    {
      id: "app_store_deployment",
      name: "AppStoreDeployment",
      content: AppStoreDeployment,
    },
    {
      id: "web_app_info",
      name: "WebAppInfo",
      content: WebAppInfo,
    },
    {
      id: "build_action_row",
      name: "BuildActionRow",
      content: BuildActionRow,
    },
    {
      id: "build_status_card",
      name: "BuildStatusCard",
      content: BuildStatusCard,
    },
    {
      id: "build_in_progress",
      name: "BuildInProgress",
      content: BuildInProgress,
    },
    {
      id: "build_message",
      name: "BuildMessage",
      content: BuildMessage,
    },
    {
      id: "build_modal",
      name: "BuildModal",
      content: BuildModal,
    },
    {
      id: "support_link_text",
      name: "SupportLinkText",
      content: SupportLinkText,
    },
    {
      id: "upload_file_button",
      name: "UploadFileButton",
      content: UploadFileButton,
    },
  ],
  screens: [
    {
      id: "build",
      name: "Build",
      content: BuildYAML,
    },
  ],
};
